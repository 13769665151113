// Utility functions

export const fetchWithErrorHandling = async (url, options) => {
    try {
      const response = await fetch(url, options);
      if (!response.ok) throw new Error(`Network response was not ok: ${response.statusText}`);
      return await response.json();
    } catch (error) {
      console.error(`Fetch error: ${error}`);
      throw error;
    }
  };
  
  export const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };
  
  export const getSentimentColor = (voteType, sentimentColors) => {
    if (voteType === 'up') return sentimentColors.up;
    if (voteType === 'neutral') return sentimentColors.neutral;
    if (voteType === 'down') return sentimentColors.down;
    return '';
  };
  