import React, { useState, useEffect } from 'react';
import VotingMode from './VotingMode';
import EditMode from './EditMode';

const App = () => {
  const [isEditing, setIsEditing] = useState(false);
  const searchParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    if (searchParams.has('edit')) {
      setIsEditing(true);
    }
  }, [searchParams]);

  return (
    <div>
      {isEditing ? <EditMode /> : <VotingMode />}
    </div>
  );
};

export default App;
