import React, { useState, useEffect, useCallback, useRef } from 'react';
import ReactCardFlip from 'react-card-flip';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import { fetchWithErrorHandling } from './utils';

const VotingMode = () => {
  const [config, setConfig] = useState(null);
  const [error, setError] = useState(null);
  const [started, setStarted] = useState(false);
  const [flipped, setFlipped] = useState({});
  const [currentStatementIndex, setCurrentStatementIndex] = useState(0);
  const [votes, setVotes] = useState({});
  const [useKidsLanguage, setUseKidsLanguage] = useState(true);
  const [statements, setStatements] = useState([]);
  const [shuffledIndices, setShuffledIndices] = useState({});
  const [kidsResponses, setKidsResponses] = useState({});
  const [adultResponses, setAdultResponses] = useState({});
  const [loadingConfig, setLoadingConfig] = useState(true);
  const [loadingData, setLoadingData] = useState(false);
  const [loadingColorIndex, setLoadingColorIndex] = useState(0);
  const [kidsData, setKidsData] = useState([]);
  const [adultData, setAdultData] = useState([]);
  const cardRefs = useRef([]);
  const logoRefs = useRef([]);

  // Fetch configuration on mount
  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const data = await fetchWithErrorHandling('/api/config');
        data.statementImages = data.statementImages || {};
        setConfig(data);
        document.title = data.textDefinitions.baseTitle;
        console.log('Config fetched successfully:', data);
      } catch (error) {
        setError('Error loading configuration. Please try again later.');
        console.error('Error loading configuration:', error.message);
      } finally {
        setLoadingConfig(false);
      }
    };
    fetchConfig();
  }, []);

  // Set interval for loading indicator color change
  useEffect(() => {
    if (!config) return;
    const intervalId = setInterval(() => {
      setLoadingColorIndex(prevIndex => (prevIndex + 1) % config.colorPalette.pastelBackground.length);
    }, 500); // Change color every 500ms
    return () => clearInterval(intervalId);
  }, [config]);

  // Function to generate shuffled indices
  const generateShuffledIndices = (length) => {
    const indices = Array.from({ length }, (_, i) => i);
    for (let i = indices.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [indices[i], indices[j]] = [indices[j], indices[i]];
    }
    return indices;
  };

  // Function to apply shuffled indices to responses
  const applyShuffledIndices = (responses, indices) => {
    return indices.map((index, idx) => ({
      response: responses[index],
      color: config.colorPalette.pastelBackground[idx % config.colorPalette.pastelBackground.length],
    }));
  };

  // Fetch both kids' and adult language data
  const fetchData = useCallback(async () => {
    if (!config) return;
    setLoadingData(true);
    try {
      const [kidsDataResponse, adultDataResponse] = await Promise.all([
        fetchWithErrorHandling(`/api/data?kids=true`),
        fetchWithErrorHandling(`/api/data?kids=false`),
      ]);

      const filteredKidsData = kidsDataResponse.filter((_, index) => config.statementIndicesToShow.includes(index + 1));
      const filteredAdultData = adultDataResponse.filter((_, index) => config.statementIndicesToShow.includes(index + 1));

      const newShuffledIndices = {};
      filteredKidsData.forEach((statement, index) => {
        newShuffledIndices[index] = generateShuffledIndices(statement.Responses.length);
      });

      const kidsShuffledResponses = {};
      const adultShuffledResponses = {};
      filteredKidsData.forEach((statement, index) => {
        kidsShuffledResponses[index] = applyShuffledIndices(statement.Responses, newShuffledIndices[index]);
      });
      filteredAdultData.forEach((statement, index) => {
        adultShuffledResponses[index] = applyShuffledIndices(statement.Responses, newShuffledIndices[index]);
      });

      setKidsData(filteredKidsData);
      setAdultData(filteredAdultData);
      setKidsResponses(kidsShuffledResponses);
      setAdultResponses(adultShuffledResponses);
      setShuffledIndices(newShuffledIndices);
      setStatements(filteredKidsData); // Default to kids data

      if (filteredKidsData.length > 0) {
        setCurrentStatementIndex(prevIndex => Math.min(prevIndex, filteredKidsData.length - 1));
      }
      console.log('Data fetched and processed successfully.');
    } catch (error) {
      setError('Error loading data. Please try again later.');
      console.error('Error loading data:', error.message);
    } finally {
      setLoadingData(false);
    }
  }, [config]);

  // Fetch data on component mount
  useEffect(() => {
    if (config) {
      fetchData();
    }
  }, [fetchData, config]);

  // Update document title based on state
  useEffect(() => {
    if (config) {
      let title = `${config.textDefinitions.baseTitle}`;
      if (!started) {
        title += ` - ${config.textDefinitions.startPageTitle}`;
      } else if (currentStatementIndex < statements.length) {
        title += ` - ${statements[currentStatementIndex]?.Label || 'Statement'}`;
      } else if (currentStatementIndex >= statements.length) {
        title += ` - ${config.textDefinitions.statsPageTitle}`;
      }
      document.title = title;
    }
  }, [started, currentStatementIndex, statements, config]);

  const handleVote = useCallback((index, party, voteType) => {
    console.log(`handleVote called: index=${index}, party=${party}, voteType=${voteType}`);
    setVotes(prevVotes => ({
      ...prevVotes,
      [currentStatementIndex]: {
        ...prevVotes[currentStatementIndex],
        [`${index}-${party}`]: voteType,
      },
    }));

    setFlipped(prevFlipped => ({
      ...prevFlipped,
      [currentStatementIndex]: {
        ...prevFlipped[currentStatementIndex],
        [`${index}-${party}`]: true,
      },
    }));
  }, [currentStatementIndex]);

  const undoVote = useCallback((index, party) => {
    console.log(`undoVote called: index=${index}, party=${party}`);
    setVotes(prevVotes => {
      const newVotes = { ...prevVotes };
      if (newVotes[currentStatementIndex]) {
        delete newVotes[currentStatementIndex][`${index}-${party}`];
      }
      return newVotes;
    });

    setFlipped(prevFlipped => {
      const newFlipped = { ...prevFlipped };
      if (newFlipped[currentStatementIndex]) {
        newFlipped[currentStatementIndex][`${index}-${party}`] = false;
      }
      return newFlipped;
    });
  }, [currentStatementIndex]);

  const navigateStatements = useCallback((direction) => {
    console.log(`navigateStatements called: direction=${direction}`);
    setCurrentStatementIndex((prevIndex) => {
      if (direction === 'start') {
        setStarted(false);
        return 0;
      }
      if (direction === 'back') {
        if (prevIndex === 0) {
          setStarted(false);
          return 0;
        }
        return prevIndex > 0 ? prevIndex - 1 : 0;
      }
      if (direction === 'next') {
        return prevIndex < statements.length - 1 ? prevIndex + 1 : statements.length;
      }
      return prevIndex;
    });
  }, [statements]);

  const resetAndRestart = useCallback(async () => {
    console.log('resetAndRestart called');
    setVotes({});
    setFlipped({});
    setStarted(false);
    setCurrentStatementIndex(0);
    await fetchData(); // Ensure data is re-fetched
  }, [fetchData]);

  const getSentimentColor = useCallback((voteType) => {
    if (voteType === 'up') return config.colorPalette.sentimentColors.up;
    if (voteType === 'neutral') return config.colorPalette.sentimentColors.neutral;
    if (voteType === 'down') return config.colorPalette.sentimentColors.down;
    return '';
  }, [config]);

  const renderResponses = useCallback(() => {
    if (loadingData || !config || statements.length === 0) {
      const loadingColor = config.colorPalette.pastelBackground[loadingColorIndex];
      return (
        <div className="flex justify-center items-center h-full">
          <div className={`flex flex-col items-center justify-between p-4 rounded-lg shadow-md`} style={{ minHeight: '200px', backgroundColor: loadingColor }}>
            <p className="text-center w-full text-2xl">Lade Antworten...</p>
          </div>
        </div>
      );
    }

    const currentStatement = statements[currentStatementIndex] || {};
    const responsesWithColors = (useKidsLanguage ? kidsResponses : adultResponses)[currentStatementIndex] || [];
    console.log('Rendering responses:', responsesWithColors);

    const cardPadding = config.cardPadding || 10;
    const buttonPadding = config.buttonPadding || 10;
    const iconSizes = config.iconSizes;

    return (
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        <div className="col-span-full text-center mb-8">
          <h2 className="text-lg font-bold mb-4">
            {currentStatement.Label}
          </h2>
          {config.statementImages[currentStatementIndex + 1] && (
            <img
              src={config.statementImages[currentStatementIndex + 1]}
              alt="statement"
              className="my-4 mx-auto"
              style={{ height: `${config.statementImageHeight}px`, objectFit: 'contain' }}
              onError={(e) => {
                console.error(`Failed to load image: ${e.target.src}`);
                e.target.style.display = 'none';
              }}
            />
          )}
          <p className="text-3xl">
            {currentStatement.Text}
          </p>
        </div>
        {responsesWithColors.length === 0 && (
          <div className="col-span-full text-center text-red-500">No responses available</div>
        )}
        {responsesWithColors.map(({ response, color }, idx) => {
          const voteKey = `${idx}-${response.Party}`;
          const voteType = votes[currentStatementIndex]?.[voteKey];
          const isFlipped = flipped[currentStatementIndex]?.[voteKey];
          const sentimentColor = getSentimentColor(voteType);
          const refIndex = currentStatementIndex * currentStatement.Responses.length + idx;

          const cardStyle = {
            minHeight: '200px',
            padding: `${cardPadding}px`,
            backgroundColor: color,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
            position: 'relative'
          };

          return (
            <ReactCardFlip key={idx} isFlipped={isFlipped} flipDirection="horizontal">
              <div ref={el => (cardRefs.current[refIndex] = el)} style={cardStyle}>
                <p className="text-center w-full">{response.Comment}</p>
                <div className={`flex mt-2 justify-around w-full`} style={{ padding: `${buttonPadding}px` }}>
                  <button onClick={() => handleVote(idx, response.Party, 'up')} className="mx-1" title={config.textDefinitions.tooltipUp} style={{ width: `${iconSizes.up.width}rem`, height: `${iconSizes.up.height}rem` }}>
                    <img src={config.imagePaths.votingIcons.up} alt="Zustimmung" />
                  </button>
                  <button onClick={() => handleVote(idx, response.Party, 'neutral')} className="mx-1" title={config.textDefinitions.tooltipNeutral} style={{ width: `${iconSizes.neutral.width}rem`, height: `${iconSizes.neutral.height}rem` }}>
                    <img src={config.imagePaths.votingIcons.neutral} alt="Neutral" />
                  </button>
                  <button onClick={() => handleVote(idx, response.Party, 'down')} className="mx-1" title={config.textDefinitions.tooltipDown} style={{ width: `${iconSizes.down.width}rem`, height: `${iconSizes.down.height}rem` }}>
                    <img src={config.imagePaths.votingIcons.down} alt="Ablehnung" />
                  </button>
                </div>
              </div>
              <div ref={el => (cardRefs.current[refIndex] = el)} style={{ ...cardStyle, backgroundColor: sentimentColor }}>
                <div className="relative flex flex-col items-center justify-between w-full h-full">
                  <p className="text-center text-opacity-50 blur-sm" style={{ zIndex: 1 }}>{response.Comment}</p>
                  <img ref={el => (logoRefs.current[refIndex] = el)} src={config.imagePaths.logos[response.Party]} alt={response.Party} className="absolute top-0 max-w-full h-auto" style={{ maxHeight: '50%', paddingTop: '10px', objectFit: 'contain', zIndex: 2 }} />
                  <div className="flex justify-around w-full mt-4" style={{ padding: `${buttonPadding}px` }}>
                    <button onClick={() => undoVote(idx, response.Party)} className="mx-1" title={config.textDefinitions.tooltipReset} style={{ width: `${iconSizes.return.width}rem`, height: `${iconSizes.return.height}rem` }}>
                      <img src={config.imagePaths.votingIcons.return} alt="Stimme zurücknehmen" />
                    </button>
                  </div>
                </div>
              </div>
            </ReactCardFlip>
          );
        })}
      </div>
    );
  }, [config, currentStatementIndex, flipped, getSentimentColor, handleVote, useKidsLanguage, kidsResponses, adultResponses, statements, undoVote, votes, loadingData, loadingColorIndex]);

  const renderStatistics = useCallback(() => {
    if (!config) return null;
    let stats = [];
    Object.entries(votes).forEach(([index, partyVotes]) => {
      Object.entries(partyVotes).forEach(([key, value]) => {
        const party = key.split('-')[1];
        if (!stats.some(s => s.name === party)) {
          stats.push({ name: party, Zustimmung: 0, Neutral: 0, Ablehnung: 0 });
        }
        const partyStat = stats.find(s => s.name === party);
        if (value === 'up') partyStat.Zustimmung += 1;
        else if (value === 'neutral') partyStat.Neutral += 1;
        else if (value === 'down') partyStat.Ablehnung += 1;
      });
    });

    stats.forEach(stat => {
      stat.Nettoergebnis = stat.Zustimmung - stat.Ablehnung;
    });

    stats.sort((a, b) => b.Nettoergebnis - a.Nettoergebnis);

    const podium = stats.slice(0, 3);
    const otherStats = stats.slice(3);

    return (
      <div className="mt-4">
        <h2 className="text-lg font-bold text-center">{config.textDefinitions.statsPageTitle}</h2>
        <p className="text-center mb-4">{config.textDefinitions.statsText}</p>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 my-4 text-center">
          {podium.map((partyStat, idx) => (
            <div key={idx} className="p-4 m-2 bg-white rounded shadow flex flex-col items-center justify-center">
              <h3 className="text-md font-semibold">{`${idx + 1}. Platz: ${partyStat.name}`}</h3>
              <p className="text-sm">{`Nettoergebnis: ${partyStat.Nettoergebnis}`}</p>
              <ResponsiveContainer width="100%" height={200 * (1 - idx * 0.1)}>
                <PieChart>
                  <Pie data={[{ name: 'Zustimmung', value: partyStat.Zustimmung }, { name: 'Neutral', value: partyStat.Neutral }, { name: 'Ablehnung', value: partyStat.Ablehnung }]} dataKey="value" cx="50%" cy="50%" outerRadius={80 * (1 - idx * 0.1)} fill="#82ca9d" label>
                    <Cell key="Zustimmung" fill="#82ca9d" />
                    <Cell key="Neutral" fill="#888888" />
                    <Cell key="Ablehnung" fill="#ff6347" />
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </div>
          ))}
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 text-center">
          {otherStats.map((partyStat, idx) => (
            <div key={idx} className="p-4 m-2 bg-white rounded shadow flex flex-col items-center justify-center">
              <h3 className="text-md font-semibold">{partyStat.name}</h3>
              <p className="text-sm">{`Nettoergebnis: ${partyStat.Nettoergebnis}`}</p>
              <ResponsiveContainer width="100%" height={100}>
                <PieChart>
                  <Pie data={[{ name: 'Zustimmung', value: partyStat.Zustimmung }, { name: 'Neutral', value: partyStat.Neutral }, { name: 'Ablehnung', value: partyStat.Ablehnung }]} dataKey="value" cx="50%" cy="50%" outerRadius={40} fill="#82ca9d" label>
                    <Cell key="Zustimmung" fill="#82ca9d" />
                    <Cell key="Neutral" fill="#888888" />
                    <Cell key="Ablehnung" fill="#ff6347" />
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </div>
          ))}
        </div>
        <div className="flex justify-center mt-4">
          <button className="bg-gray-500 text-white py-2 px-4 rounded" onClick={() => {
            console.log('Zurück button clicked');
            navigateStatements('back');
          }}>
            Zurück
          </button>
        </div>
      </div>
    );
  }, [config, votes, navigateStatements]);

  if (error) {
    return <div className="text-red-500 text-center mt-10">{error}</div>;
  }

  if (loadingConfig) {
    return <div>Loading configuration...</div>;
  }

  if (!config) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mx-auto p-5">
      {!started ? (
        <div className="flex flex-col items-center justify-center h-screen">
          <img src={config.imagePaths.institutionLogo} width="33%" height="33%" alt="Institutionslogo" className="mb-4" />
          <h1 className="text-lg text-center mb-4">
            {config.textDefinitions.willkommenText}
          </h1>
          <button className="bg-blue-500 text-white font-bold py-2 px-4 rounded" onClick={() => {
            console.log('Start button clicked');
            setStarted(true);
          }}>Start</button>
        </div>
      ) : (
        <>
          <div className="flex justify-between items-center">
            {currentStatementIndex < statements.length && (
              <button className="bg-blue-500 text-white py-2 px-4 rounded my-2" onClick={() => {
                console.log('Language toggle button clicked');
                setUseKidsLanguage(prev => !prev);
                setStatements(useKidsLanguage ? adultData : kidsData);
                setShuffledIndices(useKidsLanguage ? adultResponses : kidsResponses);
              }}>
                {useKidsLanguage ? 'Komplizierte Sprache' : 'Einfache Sprache'}
              </button>
            )}
            <button className="bg-red-500 text-white py-2 px-4 rounded ml-4" onClick={resetAndRestart}>
              Wahl löschen und neu anfangen
            </button>
          </div>
          <div className="flex">
            <div className="flex-grow">
              {currentStatementIndex < statements.length ? (
                <>
                  {renderResponses()}
                  <div className="flex justify-between my-4">
                    <button className="bg-gray-500 text-white py-2 px-4 rounded" onClick={() => {
                      console.log('Zurück button clicked');
                      if (currentStatementIndex === 0) {
                        navigateStatements('start');
                      } else {
                        navigateStatements('back');
                      }
                    }}>
                      Zurück
                    </button>
                    {Object.keys(votes[currentStatementIndex] || {}).length > 0 && (
                      <button className="bg-blue-500 text-white py-2 px-4 rounded" onClick={() => {
                        console.log('Weiter button clicked');
                        navigateStatements('next');
                      }}>
                        {currentStatementIndex === statements.length - 1 ? 'Zur Auswertung' : 'Weiter'}
                      </button>
                    )}
                  </div>
                </>
              ) : (
                <>
                  {renderStatistics()}
                </>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default VotingMode;
