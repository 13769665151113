import React, { useState, useEffect } from 'react';
import { fetchWithErrorHandling } from './utils';
import { FaSave } from 'react-icons/fa';
import axios from 'axios';

const EditMode = () => {
  const [config, setConfig] = useState(null);
  const [error, setError] = useState(null);
  const [statements, setStatements] = useState([]);
  const [adultStatements, setAdultStatements] = useState([]);
  const [currentStatement, setCurrentStatement] = useState(null);
  const [currentStatementIndex, setCurrentStatementIndex] = useState(1);
  const [unsavedChanges, setUnsavedChanges] = useState({});
  const [configUnsavedChanges, setConfigUnsavedChanges] = useState(false);
  const [editingWelcomeMessage, setEditingWelcomeMessage] = useState(false);
  const [editingResultsMessage, setEditingResultsMessage] = useState(false);
  const [lockedFields, setLockedFields] = useState({});
  const user = `user_${Math.random().toString(36).substr(2, 9)}`; // Random user ID for simulation

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const data = await fetchWithErrorHandling('/api/config');
        setConfig(data);
      } catch (error) {
        setError('Error loading configuration. Please try again later.');
        console.error('Error loading configuration:', error);
      }
    };
    fetchConfig();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (!config) return;
      try {
        const [kidsData, adultData] = await Promise.all([
          fetchWithErrorHandling(`/api/data?kids=true`),
          fetchWithErrorHandling(`/api/data?kids=false`),
        ]);

        setStatements(kidsData);
        setAdultStatements(adultData);

        if (kidsData.length > 0) {
          setCurrentStatementIndex(1);
          setCurrentStatement(kidsData[0]); // Set the first statement as the current statement
        }
      } catch (error) {
        setError('Error loading data. Please try again later.');
        console.error('Error loading data:', error);
      }
    };
    fetchData();
  }, [config]);

  const lockField = async (field) => {
    try {
      const response = await axios.post('/api/lock', { field, user });
      if (response.status === 200) {
        setLockedFields(prev => ({ ...prev, [field]: true }));
      }
    } catch (error) {
      if (error.response && error.response.status === 423) {
        alert('Dieses Feld wird gerade von einem anderen Benutzer bearbeitet.');
      } else {
        console.error('Error locking field:', error);
      }
    }
  };

  const unlockField = async (field) => {
    try {
      await axios.post('/api/unlock', { field, user });
      setLockedFields(prev => ({ ...prev, [field]: false }));
    } catch (error) {
      console.error('Error unlocking field:', error);
    }
  };

  const handleSave = async (index, field, value) => {
    const updatedStatements = [...statements];
    if (field.includes('Responses')) {
      const [resKey, resIndex, resField] = field.split('.');
      updatedStatements[index][resKey][resIndex][resField] = value;
    } else {
      updatedStatements[index][field] = value;
    }
    try {
      await fetchWithErrorHandling('/api/save-output-kids', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(updatedStatements),
      });
      setStatements(updatedStatements);
      setUnsavedChanges(prev => ({ ...prev, [field]: false }));
      await unlockField(field);
    } catch (error) {
      console.error('Error saving data:', error);
    }
  };

  const handleSaveConfig = async () => {
    try {
      await fetchWithErrorHandling('/api/save-config', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(config),
      });
      setConfigUnsavedChanges(false);
      await unlockField('config');
    } catch (error) {
      console.error('Error saving config:', error);
    }
  };

  const handleCheckboxChange = async (index) => {
    const updatedConfig = { ...config };
    const updatedIndices = config.statementIndicesToShow.includes(index + 1)
      ? updatedConfig.statementIndicesToShow.filter((i) => i !== index + 1)
      : [...updatedConfig.statementIndicesToShow, index + 1];

    updatedConfig.statementIndicesToShow = updatedIndices;

    try {
      await fetchWithErrorHandling('/api/save-config', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(updatedConfig),
      });
      setConfig(updatedConfig);
    } catch (error) {
      console.error('Error saving config:', error);
    }
  };

  const handleTextChange = async (field, value) => {
    setUnsavedChanges(prev => ({ ...prev, [field]: true }));
    if (!lockedFields[field]) {
      await lockField(field);
    }
  };

  const handleConfigTextChange = async (field, value) => {
    setConfig(prevConfig => ({
      ...prevConfig,
      textDefinitions: {
        ...prevConfig.textDefinitions,
        [field]: value
      }
    }));
    setConfigUnsavedChanges(true);
    if (!lockedFields[field]) {
      await lockField(field);
    }
  };

  const handleLeaveEditingMode = () => {
    const unsavedEdits = Object.keys(unsavedChanges).filter(key => unsavedChanges[key]);

    if (unsavedEdits.length > 0 || configUnsavedChanges) {
      const unsavedEditsList = unsavedEdits.join(', ');
      const message = `Sie haben ungespeicherte Änderungen: ${unsavedEditsList}. Möchten Sie die Bearbeitung wirklich verlassen?`;
      if (!window.confirm(message)) {
        return;
      }
    }
    window.location.href = '/';
  };

  const currentKidsStatement = statements[currentStatementIndex - 1];
  const currentAdultStatement = adultStatements[currentStatementIndex - 1];

  if (error) {
    return <div className="text-red-500 text-center mt-10">{error}</div>;
  }

  if (!config) {
    return <div>Loading...</div>;
  }

  const renderWelcomeMessageEditor = () => (
    <div>
      <h2 className="text-lg font-bold mb-2">Willkommen Nachricht</h2>
      <div className="flex items-center mb-8">
        <textarea
          value={config.textDefinitions.willkommenText}
          onChange={(e) => handleConfigTextChange('willkommenText', e.target.value)}
          rows={config.editModeTextBoxRows || 10}
          className={`w-full border p-2 ${configUnsavedChanges ? 'bg-red-100' : ''}`}
        />
        <button className="mx-2" onClick={handleSaveConfig}>
          <FaSave />
        </button>
      </div>
    </div>
  );

  const renderResultsMessageEditor = () => (
    <div>
      <h2 className="text-lg font-bold mb-2">Nachricht auf der Statistikseite</h2>
      <div className="flex items-center mb-8">
        <textarea
          value={config.textDefinitions.statsText}
          onChange={(e) => handleConfigTextChange('statsText', e.target.value)}
          rows={config.editModeTextBoxRows || 10}
          className={`w-full border p-2 ${configUnsavedChanges ? 'bg-red-100' : ''}`}
        />
        <button className="mx-2" onClick={handleSaveConfig}>
          <FaSave />
        </button>
      </div>
    </div>
  );

  const renderStatementEditor = () => (
    <div>
      <div className="grid grid-cols-2 gap-4 mb-4">
        <div className="text-center font-bold">Einfache Sprache</div>
        <div className="text-center font-bold">Komplizierte Sprache</div>
      </div>
      <h2 className="text-lg font-bold flex items-center mb-8">
        <textarea
          value={currentKidsStatement.Label}
          readOnly
          className="w-1/2 border p-2 bg-gray-200"
        />
        <textarea
          value={currentAdultStatement.Label}
          readOnly
          className="w-1/2 border p-2 bg-gray-200"
        />
      </h2>
      <div className="flex items-center mb-8">
        <textarea
          value={currentKidsStatement.Text}
          onChange={(e) => { handleTextChange('Text', e.target.value); currentKidsStatement.Text = e.target.value; }}
          rows={config.editModeTextBoxRows || 10}
          className={`w-1/2 border p-2 ${unsavedChanges['Text'] ? 'bg-red-100' : ''}`}
        />
        <button className="mx-2" onClick={() => handleSave(currentStatementIndex - 1, 'Text', currentKidsStatement.Text)}>
          <FaSave />
        </button>
        <textarea
          value={currentAdultStatement.Text}
          readOnly
          rows={config.editModeTextBoxRows || 10}
          className="w-1/2 border p-2 bg-gray-200"
        />
      </div>
      {currentKidsStatement.Responses && currentKidsStatement.Responses.map((response, idx) => (
        <div key={idx} className="mb-4">
          <div className="flex justify-center mt-2">
            <img src={config.imagePaths.logos[response.Party]} alt={response.Party} className="h-8" />
          </div>
          <div className="flex items-center">
            <textarea
              value={response.Comment}
              onChange={(e) => { handleTextChange(`Responses.${idx}.Comment`, e.target.value); response.Comment = e.target.value; }}
              rows={config.editModeTextBoxRows || 5}
              className={`w-1/2 border p-2 ${unsavedChanges[`Responses.${idx}.Comment`] ? 'bg-red-100' : ''} mt-2`}
            />
            <button className="mx-2" onClick={() => handleSave(currentStatementIndex - 1, `Responses.${idx}.Comment`, response.Comment)}>
              <FaSave />
            </button>
            <textarea
              value={currentAdultStatement.Responses[idx]?.Comment || ''}
              readOnly
              rows={config.editModeTextBoxRows || 5}
              className="w-1/2 border p-2 bg-gray-200 mt-2"
            />
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <div className="container mx-auto p-5">
      <div className="flex justify-between items-center">
        <button className="bg-red-500 text-white py-2 px-4 rounded ml-4" onClick={handleLeaveEditingMode}>
          Bearbeitung verlassen
        </button>
        {configUnsavedChanges && (
          <button className="bg-green-500 text-white py-2 px-4 rounded ml-4" onClick={handleSaveConfig}>
            Änderungen speichern
          </button>
        )}
      </div>
      <div className="flex">
        <div className="w-64 overflow-y-auto h-full border-l-2 border-gray-200">
          <div
            className={`p-2 cursor-pointer ${editingWelcomeMessage ? 'font-bold' : ''}`}
            onClick={() => {
              setEditingWelcomeMessage(true);
              setEditingResultsMessage(false);
              setCurrentStatementIndex(null);
              setCurrentStatement(null);
            }}
          >
            Willkommen Nachricht
          </div>
          {statements.map((statement, index) => (
            <div
              key={index}
              className={`p-2 cursor-pointer ${
                config.statementIndicesToShow.includes(index + 1) ? 'bg-green-200' : 'bg-red-200'
              } ${currentStatementIndex === index + 1 ? 'font-bold' : ''}`}
              onClick={() => {
                setEditingWelcomeMessage(false);
                setEditingResultsMessage(false);
                setCurrentStatementIndex(index + 1);
                setCurrentStatement(statements[index]);
              }}
            >
              <input
                type="checkbox"
                checked={config.statementIndicesToShow.includes(index + 1)}
                onChange={(e) => {
                  e.stopPropagation();
                  handleCheckboxChange(index);
                }}
              />
              <span className="ml-2">{index + 1}. {statement.Label}</span>
            </div>
          ))}
          <div
            className={`p-2 cursor-pointer ${editingResultsMessage ? 'font-bold' : ''}`}
            onClick={() => {
              setEditingWelcomeMessage(false);
              setEditingResultsMessage(true);
              setCurrentStatementIndex(null);
              setCurrentStatement(null);
            }}
          >
            Statistik Nachricht
          </div>
        </div>
        <div className="flex-grow p-4">
          {editingWelcomeMessage && renderWelcomeMessageEditor()}
          {editingResultsMessage && renderResultsMessageEditor()}
          {currentStatement && renderStatementEditor()}
        </div>
      </div>
    </div>
  );
};

export default EditMode;
